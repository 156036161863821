<template>
    <div class="home">
        <div class="banner" @click="onClick(cehis[suijishu])" v-if="cehis[suijishu]" style="margin-bottom:10px">
            <img :src="cehis[suijishu].imgindex" alt="">
        </div>
        <div class="kuai yyguan">
            <div class="neikuai yykuai1" v-if="topIndexData.operating != null">
                <div class="img">
                    <img :src="topIndexData.operating.upload_img" alt="">
                    <p>我的运营官</p>
                </div>
                <div class="text">
                    <p class="name">{{topIndexData.operating.operating_name}}</p>
                    <p class="phone" v-if="topIndexData.operating.operating_mobile"><i class="iconfont icon-shouji" />{{topIndexData.operating.operating_mobile}}</p>
                </div>
            </div>
            <div class="neikuai" v-else>
                <el-empty description="暂无绑定运营官" :image-size="100"></el-empty>
            </div>
            <div class="neikuai yykuai2" v-if="topIndexData.topTopCorporateAccount">
                <i class="iconfont icon-dianfu"></i>
                <p class="num">{{topIndexData.topTopCorporateAccount.payment_price}}</p>
                <p class="name">我的垫款额度</p>
            </div>
            <div class="neikuai" v-else>
                <el-empty description="暂无垫款额度" :image-size="100"></el-empty>
            </div>
            <div class="neikuai yykuai2" v-if="topIndexData.topTopCorporateAccount">
                <i class="iconfont icon-dianfu"></i>
                <p class="num">{{topIndexData.topTopCorporateAccount.total_score}}</p>
                <p class="name">我的积分</p>
            </div>
            <div class="neikuai" v-else>
                <el-empty description="暂无积分" :image-size="100"></el-empty>
            </div>
        </div>

        <!-- 成本消耗数据 -->
        <!-- <div class="kuai condata" @click="ontoRouter('/dataShow')">
			<div class="title">上次消耗成本数据</div>
			<div class="tablist">
				<div class="tabdan">
					<div class="num" v-if="ReportList.stat_cost">{{ReportList.stat_cost.toFixed(2)}}</div>
					<div class="num" v-else>--</div>
					<div class="text1">消耗（元）</div>
				</div>
				<div class="tabdan">
					<div class="num" v-if="ReportList.show_cnt">{{ReportList.show_cnt}}</div>
					<div class="num" v-else>--</div>
					<div class="text1">展示次数</div>
				</div>
				<div class="tabdan">
					<div class="num" v-if="ReportList.ctr">{{ReportList.ctr}}%</div>
					<div class="num" v-else>--</div>
					<div class="text1">点击率</div>
				</div>
				<div class="tabdan">
					<div class="num" v-if="ReportList.pay_order_count">{{ReportList.pay_order_count}}</div>
					<div class="num" v-else>--</div>
					<div class="text1">成交订单数</div>
				</div>
				<div class="tabdan">
					<div class="num" v-if="ReportList.pay_order_amount">{{ReportList.pay_order_amount}}</div>
					<div class="num" v-else>--</div>
					<div class="text1">成交订单金额</div>
				</div>
			</div>
		</div> -->
        <!-- 工单复审，充值计算器 -->
        <div class="kuai fushen">
            <div class="neikuai" @click="ontoRouter('/advances')">
                <div class="title">充值统计</div>
                <div class="shujdata" v-if="topIndexData.topTopCorporateAccount">
                    <div class="gongdinfo">
                        <p class="text">已充值(笔)</p>
                        <p class="num hong">{{topIndexData.topTopCorporateAccount.orderCount}}</p>
                    </div>
                    <div class="weitinfo">
                        <p>总广告币：<span class="hong">{{topIndexData.topTopCorporateAccount.total_actual_price}}</span></p>
                        <p>总打款：<span class="hong">￥{{topIndexData.topTopCorporateAccount.total_price}}</span></p>
                    </div>
                </div>
                <div class="neikuai" style="margin-bottom:15px" v-else>
                    <el-empty description="暂无数据" :image-size="100"></el-empty>
                </div>
            </div>

            <div class="neikuai" @click="ontoRouter('/workList')">
                <div class="title">工单复审</div>
                <div class="shujdata" v-if="topIndexData.countRepairOrder">
                    <div class="gongdinfo">
                        <p class="text">已提交(笔)</p>
                        <p class="num hong">{{topIndexData.countRepairOrder.all}}</p>
                    </div>
                    <div class="weitinfo">
                        <p>未通过：<span class="green">{{topIndexData.countRepairOrder.rejected}}</span></p>
                        <p>已通过：<span class="hong">{{topIndexData.countRepairOrder.shutdown}}</span></p>
                    </div>
                </div>
                <div class="neikuai" style="margin-bottom:15px;border-left: 1px solid #ededed;" v-else>
                    <el-empty description="暂无数据" :image-size="100"></el-empty>
                </div>
            </div>
            <!-- <div class="neikuai">
				<div class="title">充值计算器(最近一条记录)</div>
				<div class="shujdata">
					<div class="gongdinfo">
						<p class="text">到账</p>
						<p class="num">3000</p>
					</div>
					<div class="weitinfo">
						<span>政策：4.0</span>
						<span>打款：￥2888.16</span>
					</div>
				</div>
			</div> -->
        </div>
        <div class="kuai biaoge">
            <div class="neikuai">
                <div class="title"><div>充值记录<el-button type="text" icon="el-icon-download" @click="onChongzhi()">导出</el-button></div></div>
                <el-table ref="refTable" :data="OrderList" border style="width: 100%">
                    <el-table-column prop="order_detail.top_agent_account.advertiser.advertiser_name" label="端口"></el-table-column>
                    <el-table-column prop="corporate_account_info.corporate_account_name" label="企业账户名称"></el-table-column>
                    <el-table-column prop="company_info.company_name" label="群名称"></el-table-column>
                    <el-table-column prop="order_detail.top_agent_account.agent_account_name" label="账户名称"></el-table-column>
                    <el-table-column prop="order_detail.top_agent_account.agent_account" label="代理商账户"></el-table-column>
                    <el-table-column prop="order_detail.top_agent_account.agent_account_name" label="对公/对私">
                        <template slot-scope="scope">
                            {{scope.row.back_card_info ? (scope.row.back_card_info.card_status == 1 ? '公户'  : '私户') : '--'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="order_price" label="实际金额"></el-table-column>
                    <el-table-column prop="order_detail.user_record_price" label="到账金额"></el-table-column>
                    <el-table-column prop="back_card_info.account_name" label="收款人">
                        <template slot-scope="scope">
                            {{scope.row.back_card_info ? scope.row.back_card_info.account_name : '--'}}
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="created_at" label="创建时间"></el-table-column>
                    <el-table-column prop="updated_at" label="完成时间"></el-table-column>
                    <!-- <el-table-column prop="arrival_time" label="预计到账时间">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.order_status == 2">已完成</el-tag>
                            <div v-else-if="scope.row.predict_finish_show_text != null">{{scope.row.predict_finish_show_text}}</div>
                            <div v-else>--</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="下单时间"></el-table-column> -->
                </el-table>
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
                <!-- <el-table ref="refTable" :data="OrderList" border style="width: 100%">
                    <el-table-column prop="order_price" label="打款金额">
                        <template slot-scope="scope">
                            <div class="price">￥{{scope.row.order_price}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="arrival_time" label="预计到账时间">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.order_status == 2">已完成</el-tag>
                            <div v-else-if="scope.row.predict_finish_show_text != null">{{scope.row.predict_finish_show_text}}</div>
                            <div v-else>--</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="下单时间"></el-table-column>
                </el-table> -->
            </div>
            <!-- <div class="neikuai" @click="ontoRouter('/counter')">
                <div class="title">广告币计算记录 <span>更多>></span></div>
                <el-table ref="refTable" :data="conterlist" border style="width: 100%">
                    <el-table-column prop="order_price" label="到账广告币">
                        <template slot-scope="scope">
                            <div class="price">￥{{scope.row.adv}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="order_price" label="打款金额">
                        <template slot-scope="scope">
                            <div class="price">￥{{scope.row.money}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="policy" label="政策">
                        <template slot-scope="scope">
                            <div class="jieguo">{{scope.row.policy}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div> -->
        </div>

    </div>
</template>

<script>
import moment from "moment";//时间解析
export default {
    data() {
        return {
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页

            topIndexData: {},//首页数据
            ReportList: {},
            accountsValue: "",//第一个
            accoutValue: '',//第二个
            ShopAdvertisersValue: '',//第三个
            qcTimevalue: [],
            start_date: '',
            end_date: '',
            userinfo: JSON.parse(localStorage.getItem('TZ-USER')),
            OrderList: [],//充值记录
            conterlist: [],//广告币计算记录
            cehis:[
				{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist1.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist1.jpg',
					top_menu_id: 40,
					top_menu_status: "1"
				},
				{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist3.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist3.jpg',
					top_menu_id: 27,
					top_menu_status: "1"
				},
				// {
				// 	imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist34.gif',
				// 	imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist34.gif',
				// 	top_menu_id: 'http://user.erlangcha.com/',
				// 	top_menu_status: "2"
				// },
				{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist2.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist2.jpg',
					top_menu_id: 35,
					top_menu_status: "1"
				},
				
				{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist5.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist5.jpg',
					top_menu_id: 4,
					top_menu_status: "1"
				},
				{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist36.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist36.jpg',
					top_menu_id: 80,
					top_menu_status: "1"
				},{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist37.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist37.jpg',
					top_menu_id: 81,
					top_menu_status: "1"
				},{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist38.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist38.jpg',
					top_menu_id: 82,
					top_menu_status: "1"
				},{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist39.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist39.jpg',
					top_menu_id: '82?xinshouqi=banner2',
					top_menu_status: "1"
				},{
					imga:'https://erlangcha.oss-cn-beijing.aliyuncs.com/child/shouye/danlist40.jpg',
					imgindex:'https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/danlist40.jpg',
					top_menu_id: 84,
					top_menu_status: "1"
				},
			],
            suijishu: '',
        }
    },
    components: {
    },
    mounted() {
        //广告图顺序显示（打开一个下次再打开就是下一个广告图）
        if(localStorage.getItem('TZ-SHUJU')){
            this.suijishu = localStorage.getItem('TZ-SHUJU') * 1
            var changdu = this.suijishu+1
            if(changdu > this.cehis.length-1){
                localStorage.setItem('TZ-SHUJU', 0)
            }else{
                localStorage.setItem('TZ-SHUJU', this.suijishu+1)
            }
        }else{
            localStorage.setItem('TZ-SHUJU', 0)
            this.suijishu = [0]
        }
        if (localStorage.getItem('TZ-USER')) {
            console.log(1111)
            this.onNumKuai()
            this.ongetOrderList()
            this.ongetAdvList()
            this.ontopIndex()//首页数据
        }
    },

    methods: {
        //充值记录导出
        onChongzhi(){
            window.open(this.$api.getIndexOrderList + '?export=1' + '&token=' + this.userinfo.token)
        },
        // 块1数据获取
        onNumKuai() {
            if (this.$route.path != '/') {
                if (localStorage.getItem('ACCOUT-VALUE')) {
                    this.ongetaccounts()
                } else {
                    this.onTimeChange()
                    this.ongetaccountswu()
                }
            }

        },
        //数据返回只需要第一个和第三个id选择
        //有缓存获取授权账号列表
        ongetaccounts() {
            if (localStorage.getItem('ACCOUT-VALUE')) {
                this.accountsValue = JSON.parse(localStorage.getItem('ACCOUT-VALUE'))
                if (localStorage.getItem('REPRO-VALUE')) {
                    this.accoutValue = JSON.parse(localStorage.getItem('REPRO-VALUE')).accoutValue
                    this.ShopAdvertisersValue = JSON.parse(localStorage.getItem('REPRO-VALUE')).ShopAdvertisersValue
                    this.getTimeFn()
                    this.onTimeChange()
                } else {
                    this.ongetAuthShopswu()
                }
            }
            this.$service.get(this.$api.getaccounts, {}, (res) => {
                if (res.code == '200') {
                    if (res.data.length == 0) {
                        localStorage.removeItem('ACCOUT-VALUE')
                        localStorage.removeItem('REPRO-VALUE')
                    }
                    res.data.forEach((v, k) => {
                        // console.log(v.id,123123)
                        if (v.id != JSON.parse(localStorage.getItem('ACCOUT-VALUE'))) {
                            localStorage.removeItem('ACCOUT-VALUE')
                            localStorage.removeItem('REPRO-VALUE')
                        }
                    });
                }
            })
        },
        //无缓存第一个接口
        ongetaccountswu() {
            this.$service.get(this.$api.getaccounts, {}, (res) => {
                if (res.code == '200') {
                    if (res.data.length == 0) {
                        this.accountsValue = ''
                    } else {
                        this.accountsValue = res.data[0].id
                        this.ongetAuthShopswu()
                    }
                }
            })
        },
        // 无缓存第二个接口，这个接口只是为了获取第三个id
        ongetAuthShopswu() {
            var param = {
                page: 1,
                size: 20,
                auth_id: this.accountsValue
            }
            this.$service.get(this.$api.getAuthShops, param, (res) => {
                if (res.code == '200') {
                    if (res.data.list.length) {
                        this.ongetShopAdvertiserswu(res.data.list[0].advertiser_id)
                    }
                }
            })
        },
        // 第三次选择账户接口
        ongetShopAdvertiserswu(advertiser_id) {
            var param = {
                auth_id: this.accountsValue,
                advertiser_id: advertiser_id,
            }
            this.$service.get(this.$api.getShopAdvertisers, param, (res) => {
                if (res.code == '200') {
                    this.ShopAdvertisersValue = res.data[0].id
                    this.getTimeFn()
                    this.onTimeChange()
                }
            })
        },
        //时间选择
        onTimeChange() {
            this.start_date = this.qcTimevalue ? moment(this.qcTimevalue[0]).format("YYYY-MM-DD") : "",
                this.end_date = this.qcTimevalue ? moment(this.qcTimevalue[1]).format("YYYY-MM-DD") : ""
            if (this.ShopAdvertisersValue) {
                this.ongetReport()
            }
        },
        getTimeFn() {
            // this.qcTimevalue = ['2021-07-01','2021-07-15']
            this.qcTimevalue = [this.addtimeDate(-7), this.addtimeDate(0)]
        },

        addtimeDate(days) {
            var d = new Date();
            d.setDate(d.getDate() + days);
            var m = d.getMonth() + 1;
            return d.getFullYear() + '-' + m + '-' + d.getDate();
        },
        set_time(str) {
            var n = parseInt(str) * 1000;
            var D = new Date(n);
            var year = D.getFullYear();//四位数年份

            var month = D.getMonth() + 1;//月份(0-11),0为一月份
            month = month < 10 ? ('0' + month) : month;

            var day = D.getDate();//月的某一天(1-31)
            day = day < 10 ? ('0' + day) : day;

            var hours = D.getHours();//小时(0-23)
            hours = hours < 10 ? ('0' + hours) : hours;

            var minutes = D.getMinutes();//分钟(0-59)
            minutes = minutes < 10 ? ('0' + minutes) : minutes;


            var now_time = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return now_time;
        },
        // 选择完毕后调取账户数据
        ongetReport() {
            var param = {
                advertiser_id: this.ShopAdvertisersValue,
                auth_id: this.accountsValue,
                start_date: this.start_date,
                end_date: this.end_date,
            }
            this.$service.get(this.$api.getReport, param, (res) => {
                if (res.code == '200') {
                    if (!res.data.count.click_cnt) { this.$message({ message: '暂未获取到消耗成本数据', type: 'warning' }); }
                    this.ReportList = res.data.count
                }
            })
        },




        // 上边是消耗成本数据
        ontoRouter(path) {
            this.$router.push(path)
        },


        // 自助充值列表
        ontopIndex() {
            this.$service.get(this.$api.topIndex, {}, (res) => {
                if (res.code == '200') {
                    this.topIndexData = res.data
                }
            })
        },
        // 自助充值列表
        ongetOrderList() {
            this.OrderList = []
            var param = {}
            param.page = this.paramPage
            param.per_page = this.per_page
            this.$service.get(this.$api.getIndexOrderList, param, (res) => {
                if (res.code == '200') {
                    this.OrderList = res.data.data;
                    this.lvtotal = res.data.total;
                    // this.OrderList = res.data.data.slice(0, 3);
                }
            })
        },
         // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetOrderList()
        },
        // 广告币计算列表
        ongetAdvList() {
            var prame = {
                current_page: 1,
                limit: 3,
            }
            this.$service.get(this.$api.getAdvList, prame, (res) => {
                if (res.code == '200') {
                    this.conterlist = res.data.list
                }
            })
        },



        //点击跳转广告页面
        onClick(item) {
			if(item.top_menu_status == 2){
                    window.open(item.top_menu_id)
				window.open(item.top_menu_id);  
			}else{
				if (item.top_menu_id) {
                    window.open('https://www.erlangcha.com/htmlText/' + item.top_menu_id)
				}else{
                    window.open('https://www.erlangcha.com/htmlText/' + item.top_menu_id)
				}
			}	
		}
    }
}
</script>

<style lang="scss" scoped>
.home {
    .title {
        cursor: pointer;
    }
    .hong {
        color: #f00;
    }
    .green {
        color: #70d459;
    }
    .kuai {
        background: #fff;
        border-radius: 5px;
        // box-shadow: 0 0 3px 0 #ededed;
        width: 100%;
        padding: 15px 20px;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
        .title {
            font-size: 20px;
            font-weight: 500;
        }
        &.condata {
            .tablist {
                display: flex;
                // flex-wrap: wrap;
                .tabdan {
                    width: 18%;
                    // width: 280px;
                    height: 140px;
                    // background: #f5f5f5;
                    // border-radius: 10px;
                    margin-top: 10px;
                    text-align: center;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                    border-right: 1px solid #ededed;
                    &:last-child {
                        border: none;
                    }
                    .iconbjtu {
                        color: #7290ff;
                        position: absolute;
                        bottom: -30px;
                        right: -20px;
                        opacity: 0.1;
                        i {
                            font-size: 120px;
                        }
                    }
                    &:hover {
                        background: #2251db;
                        color: #fff;
                        .num {
                            color: #fff;
                        }
                    }
                    .text1 {
                        margin-top: 10px;
                        font-size: 16px;
                        i {
                            font-size: 20px;
                            color: #7290ff;
                        }
                        z-index: 1;
                    }
                    .num {
                        font-size: 42px;
                        font-weight: 500;
                        color: #2251db;
                        // color: #2251db;
                        margin-top: 10px;
                    }
                }
            }
        }
        &.fushen {
            display: flex;
            padding: 0;
            .neikuai {
                min-width: 25%;
                border: none;
            }
            .title {
                padding: 10px;
                padding-left: 20px;
                // border-bottom: 1px solid #ededed;
            }
            .xian {
                margin: 40px 30px;
                width: 1px;
                background: #ededed;
            }
            .shujdata {
                // padding: 20px 50px;
                border-right: 1px solid #ededed;

                .gongdinfo {
                    text-align: center;
                    .text {
                        // margin-top: 30px;
                        font-size: 18px;
                        font-weight: 500;
                    }
                    .num {
                        font-size: 28px;
                        font-weight: 500;
                        margin-top: 10px;
                    }
                }
                .weitinfo {
                    display: flex;
                    margin: 20px 0;
                    margin-top: 30px;
                    justify-content: center;
                    p {
                        margin: 0 20px;
                        font-size: 16px;
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &.self {
            .toubu {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .righttext {
                    display: flex;
                    justify-content: space-between;
                    p {
                        margin-left: 30px;
                        font-size: 20px;
                        font-weight: 500;
                        span {
                            font-size: 20px;
                            color: #f00;
                        }
                    }
                }
            }
        }
        &.yyguan {
            display: flex;
            flex-wrap: wrap;
            .neikuai {
                width: 25%;
                min-height: 120px;
            }
            .yykuai1 {
                display: flex;
                .img {
                    text-align: center;
                    padding-left: 30px;
                    img {
                        border-radius: 100%;
                        height: 80px;
                        width: 80px;
                    }
                    p {
                        font-size: 18px;
                        margin-top: 10px;
                    }
                }
                .text {
                    margin-top: 35px;
                    margin-left: 40px;
                    .name {
                        font-size: 24px;
                    }
                    .phone {
                        margin-top: 10px;
                    }
                }
            }
            .yykuai2 {
                justify-content: center;
                display: flex;
                align-items: center;
                text-align: center;
                i {
                    font-size: 54px;
                    color: #1190ea;
                }
                .num {
                    margin-left: 30px;
                    font-size: 38px;
                    font-weight: 500;
                }
                .name {
                    font-size: 18px;
                    margin-left: 30px;
                }
                &:nth-child(3) {
                    i {
                        color: #fed177;
                    }
                }
            }
        }
        &.biaoge {
            display: flex;
            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                    color: #2251db;
                    font-size: 16px;
                    &:hover {
                        color: #7290ff;
                    }
                }
            }
            .neikuai {
                width: 100%;
                margin-left: 40px;
                border: none;
                &:first-child {
                    margin-left: 0;
                }
            }
            .el-table {
                margin-top: 10px;
            }
        }
        .neikuai {
            cursor: pointer;
            border-left: 1px solid #ededed;
            &:first-child {
                border: none;
            }
        }
    }
}
@media screen and (max-width: 750px) {
    .home {
        .kuai {
            padding: 0;
            .neikuai {
                border: none;
            }
            &.yyguan {
                display: block;
                .yykuai1 {
                    width: 100%;
                    height: 3rem;
                    border-bottom: 1px solid #ededed;
                    .img {
                        width: 4rem;
                        padding-top: 0.2rem;
                        padding-left: 0.2rem;
                    }
                    .text {
                        margin-top: 0.8rem;
                        margin-left: 0.3rem;
                    }
                }
                .yykuai2 {
                    border-bottom: 1px solid #ededed;
                    height: 2rem;
                    width: 100%;
                }
            }
            &.condata {
                margin-bottom: 0;
                border-bottom: 1px solid #ededed;
                .tablist {
                    flex-wrap: wrap;
                    .tabdan {
                        width: 3.4rem;
                        height: 2.6rem;
                        margin: 0;
                        margin-top: 0.3rem;
                        border-right: none;
                        .iconbjtu {
                            i {
                                font-size: 2rem;
                            }
                        }
                        .text1 {
                            margin-top: 0.4rem;
                            font-size: 0.3rem;
                            i {
                                font-size: 0.3rem;
                            }
                        }
                        .num {
                            margin-top: 0.1rem;
                            font-size: 0.5rem;
                        }
                        .prompt {
                            font-size: 0.2rem;
                            margin-top: 0.1rem;
                            .hong {
                                font-size: 0.27rem;
                            }
                            .lv {
                                font-size: 0.27rem;
                            }
                        }
                    }
                }
            }
            &.fushen {
                display: block;
                margin-bottom: 0;
                .shujdata {
                    padding: 0;
                    border: none;
                    border-bottom: 1px solid #ededed;
                }
            }
            &.biaoge {
                display: block;
                .neikuai {
                    width: 100%;
                    margin-bottom: 0.3rem;
                    margin-left: 0;
                    border-bottom: 1px solid #ededed;
                    padding-bottom: 0.3rem;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.home {
    .el-table td,
    .el-table th {
        padding: 8px 0;
    }
    .el-table {
        .cell {
            text-align: center;
        }
        .price {
            font-size: 18px;
            font-weight: 500;
            color: #f00;
        }
    }
}
</style>
